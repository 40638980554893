
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxSummary,
  DxTotalItem,
  DxValueFormat,
} from 'devextreme-vue/data-grid';

import { taskdetails } from './../data/data.js';


export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxSummary,
    DxTotalItem,
    DxValueFormat,
  },
  props: {
    productId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      taskdetail: taskdetails,
      columns: ['equipo', 'tipo', 'descripcion'],
      currentMode: 'nextColumn',
    };
  },
};
